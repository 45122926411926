import React, { useState } from 'react';
import Modal from '../../Utilities/Modal';
import { Card, CardBody } from '@paljs/ui/Card';
import { toast } from 'react-toastify';
import './weddingStyles.css';
import LoadingSpinner from '../loader';
import { moveSubeventPhotos } from '../../actions/weddingAction';

const MovePhotoModal = ({ weddingId, movingFromEvent, movingPhotoData, onClose, subEvents = [], postMovePhoto }) => {
  const [moveTo, setMoveTo] = useState('');
  const [loading, setLoading] = useState(false);

  const handleMove = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const moveData = {
        move_from_subevent_id: movingPhotoData?.subevent_id,
        move_to_subevent_id: moveTo,
        subevent_photo_ids: [movingPhotoData?._id],
      };
      const result = await moveSubeventPhotos(weddingId, moveData);
      if (result.status) {
        toast.success('Photo moved successfully');
        postMovePhoto();
      } else toast.error('Error: ', result?.error_message);
    } catch (error) {
      console.error('Failed to move photo:', error);
      toast.error('Failed to move photo');
    }
    setLoading(false);
    onClose();
  };

  const filteredSubEvents = subEvents?.filter((subEvent) => subEvent?.subevent_name !== movingFromEvent);

  return (
    <Modal toggle={onClose}>
      <Card>
        <CardBody>
          {loading ? (
            <LoadingSpinner message="Moving Photo" />
          ) : (
            <>
              <form className="form-container" onSubmit={handleMove}>
                <h2>Move Photo</h2>
                <div>
                  <label>Move From:</label>
                  <input className="input-class" type="text" value={movingFromEvent} readOnly />
                </div>
                <div>
                  <label>Move To:</label>
                  <select className="input-class" value={moveTo} onChange={(e) => setMoveTo(e.target.value)} required>
                    <option value="" disabled>
                      Select sub-event
                    </option>
                    {filteredSubEvents?.map((subEvent) => (
                      <option key={subEvent?._id} value={subEvent?._id}>
                        {subEvent?.subevent_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <button type="button" onClick={onClose}>
                    Cancel
                  </button>
                  <button type="submit">Move</button>
                </div>
              </form>
            </>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};

export default MovePhotoModal;
